<template>
  <div>
    <b-row class="m-0">
      <b-col class="content-header" cols="12">
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search-filter mr-1">
          <b-input-group>
            <div class="position-relative w-100 d-flex align-items-center">
              <b-button
                variant="transparent"
                class="p-0 position-absolute btn-gray br border-right-0"
                style="left: 0.5rem; z-index: 1"
              >
                <span class="material-icons-outlined text-secondary"
                  >event_note</span
                >
              </b-button>
              <date-range-picker
                ref="picker"
                id="dates"
                @update="setDates"
                :show-week-numbers="true"
                :timePicker="false"
                :opens="'right'"
                :append-to-body="true"
                :locale-data="config"
                :ranges="defaultRange"
                v-model="filterRange"
                :auto-apply="true"
              >
              </date-range-picker>
            </div>
          </b-input-group>
        </div>
        <div class="content-header-search mr-1">
          <b-input-group>
            <div class="position-relative w-100 d-flex align-items-center">
              <b-button
                variant="transparent"
                class="p-0 position-absolute btn-gray br border-right-0"
                style="left: 0.5rem"
              >
                <span class="material-icons-outlined text-secondary"
                  >search</span
                >
              </b-button>
              <b-form-input
                id="searchWrite"
                v-model="searchWrite"
                placeholder="Rechercher une ecriture"
                v-on:input="onFilterTextBoxChanged()"
                class="w-full pl-4"
                style="
                  border-top-right-radius: 0.357rem;
                  border-bottom-right-radius: 0.357rem;
                "
              >
              </b-form-input>
              <b-button
                variant="transparent"
                class="p-0 position-absolute btn-gray hover"
                :class="
                  this.gridOptions.floatingFiltersHeight > 0 ? 'active' : ''
                "
                style="right: 10px"
                @click="showRowFilter()"
              >
                <span
                  class="material-icons-outlined"
                  :class="
                    this.gridOptions.floatingFiltersHeight > 0
                      ? 'text-primary active'
                      : 'text-secondary'
                  "
                  >filter_alt</span
                >
              </b-button>
            </div>
          </b-input-group>
        </div>
        <div class="content-header-actions">
          <b-dropdown
            class="bg-outline-primary btn-group-icon"
            variant="outline-primary"
          >
            <template #button-content>
              <span class="material-icons-outlined m-0 p-0">settings</span>
            </template>
            <b-dropdown-item
              class="w-full border-bottom-grey-light"
              @click="togglePanelColumn"
              >{{
                this.gridOptions.sideBar == null
                  ? "Gérer les colonnes"
                  : "Masquer la gestion des colonnes"
              }}</b-dropdown-item
            >
            <b-dropdown-item class="w-full" @click="exportDataCSV"
              >Exporter en CSV</b-dropdown-item
            >
            <b-dropdown-item class="w-full" @click="exportDataXLS"
              >Exporter en XLS</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </b-col>
      <b-col cols="12" class="content-header d-sm-none my-0 py-0">
        <div class="content-header-search-filter-mobile">
          <b-input-group>
            <div class="position-relative w-100 d-flex align-items-center">
              <b-button
                variant="transparent"
                class="p-0 position-absolute btn-gray br border-right-0"
                style="left: 0.5rem; z-index: 1"
              >
                <span class="material-icons-outlined text-secondary"
                  >event_note</span
                >
              </b-button>
              <date-range-picker
                ref="picker"
                id="dates"
                @update="setDates"
                :show-week-numbers="true"
                :timePicker="false"
                :opens="'right'"
                :append-to-body="true"
                :locale-data="config"
                :ranges="defaultRange"
                v-model="filterRange"
                :auto-apply="true"
              >
              </date-range-picker>
            </div>
          </b-input-group>
        </div>
      </b-col>
      <b-col cols="12" class="content-header d-sm-none my-0 py-0">
        <div class="content-header-search-mobile">
          <b-input-group>
            <div class="position-relative w-100 d-flex align-items-center">
              <b-button
                variant="transparent"
                class="p-0 position-absolute btn-gray br border-right-0"
                style="left: 0.5rem"
              >
                <span class="material-icons-outlined text-secondary"
                  >search</span
                >
              </b-button>
              <b-form-input
                id="searchWrite"
                v-model="searchWrite"
                placeholder="Rechercher une ecriture"
                v-on:input="onFilterTextBoxChanged()"
                class="w-full pl-4"
                style="
                  border-top-right-radius: 0.357rem;
                  border-bottom-right-radius: 0.357rem;
                "
              >
              </b-form-input>
              <b-button
                variant="transparent"
                class="p-0 position-absolute btn-gray hover"
                :class="
                  this.gridOptions.floatingFiltersHeight > 0 ? 'active' : ''
                "
                style="right: 10px"
                @click="showRowFilter()"
              >
                <span
                  class="material-icons-outlined"
                  :class="
                    this.gridOptions.floatingFiltersHeight > 0
                      ? 'text-primary active'
                      : 'text-secondary'
                  "
                  >filter_alt</span
                >
              </b-button>
            </div>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div
        class="text-center flex-center"
        v-if="isLoadingExportSalesJournalCsv"
      >
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement de l'export CSV...
        </div>
      </div>
      <ag-grid-vue
        v-else
        style="width: 100%; height: 100%"
        class="ag-theme-material"
        :rowData="gridData"
        :gridOptions="gridOptions"
        :animateRows="true"
        :enableCellChangeFlash="true"
        :key="filter"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";
import {
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Ce mois-ci": [
    new Date(dayjs().startOf("month").hour(12)),
    new Date(dayjs().endOf("month")),
  ],
  "Mois dernier": [
    new Date(dayjs().subtract(1, "month").startOf("month").hour(12)),
    new Date(dayjs().subtract(1, "month").endOf("month")),
  ],
  "Ce trimestre": [
    new Date(dayjs().startOf("quarter").hour(12)),
    new Date(dayjs().endOf("quarter")),
  ],
  "Trimestre dernier": [
    new Date(dayjs().subtract(1, "quarter").startOf("quarter").hour(12)),
    new Date(dayjs().subtract(1, "quarter").endOf("quarter")),
  ],
  "Cette année": [
    new Date(dayjs().startOf("year").hour(12)),
    new Date(dayjs().endOf("year")),
  ],
  "Année dernière": [
    new Date(dayjs().subtract(1, "year").startOf("year").hour(12)),
    new Date(dayjs().subtract(1, "year").endOf("year")),
  ],
  "Depuis le début": [
    new Date(dayjs("2010-01-01")),
    new Date(dayjs().endOf("year")),
  ],
};

export default {
  components: {
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,
    AgGridVue,
    DateRangePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: config,
      defaultRange: defaultRange,
      filter: false,
      sidebar: false,
      gridOptions: {
        floatingFiltersHeight: 0,
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 20,
        enableCellChangeFlash: "true",
      },
      gridApi: null,
      columnApi: null,
      searchWrite: "",
      filterRange: {
        startDate: new Date(
          dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        endDate: new Date(
          dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        ).toISOString(),
      },
    };
  },
  created() {
    this.getExportCsvPeriodByInstitution({
      startTo: new Date(this.filterRange.startDate).toISOString(),
      endTo: new Date(this.filterRange.endDate).toISOString(),
    });
  },
  computed: {
    ...mapGetters([
      "exportSalesJournalCsv",
      "isLoadingExportSalesJournalCsv",
      "institution",
    ]),
    gridData() {
      return this.$store.getters.exportSalesJournalCsv;
    },
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
        headerName: "Société",
        field: "institutionName",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Client",
        field: "companyName",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Date",
        field: "documentDate",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Numéro",
        field: "documentReference",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Numéro référence",
        field: "documentNumber",
        initialHide: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Code journal",
        field: "logCode",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Libellé",
        field: "label",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Sens de l'écriture",
        field: "direction",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Compte",
        field: "accountNumber",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
      {
        headerName: "Montant",
        field: "value",
        initialHide: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        maxWidth: 350,
      },
    ];
  },
  methods: {
    ...mapActions(["getExportCsvPeriodByInstitution"]),
    formatCurrency,
    async setDates(dates) {
      let dateRange = {
        startDate: new Date(dates.startDate),
        endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
      };
      await this.getExportCsvPeriodByInstitution({
        startTo: dateRange.startDate,
        endTo: dateRange.endDate,
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchWrite);
    },
    showRowFilter() {
      if (this.gridOptions.floatingFiltersHeight > 0) {
        this.gridApi.setFloatingFiltersHeight(0);
        this.gridApi.setFilterModel(null);
      } else {
        this.gridApi.setFloatingFiltersHeight(36);
      }
    },
    togglePanelColumn() {
      if (!this.gridOptions.sideBar || this.gridOptions.sideBar == null) {
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Colonnes",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        };
        this.filter = true;
      } else {
        this.gridOptions.sideBar = null;
        this.filter = false;
      }
    },
    exportDataXLS() {
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV() {
      this.gridApi.exportDataAsCsv();
    },
  },
};
</script>
